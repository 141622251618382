.record-audio-modal {
  width: 95% !important;
  max-width: 360px;
  top: 65px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .record-audio-modal {
    top: 92px;
  }
}
.record-audio-modal .ant-modal-content {
  border-radius: 10px;
  background-color: #fff !important;
}
.record-audio-modal .ant-modal-body {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 420px;
  margin: 0 auto;
  text-align: center;
}
.record-audio-modal .ant-modal-content h1 {
  color: #444;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: unset;
}
.record-audio-modal .ant-modal-close-x {
  width: 40px;
  height: 40px;
}
.record-audio-modal .ant-modal-close-x .anticon {
  width: 20px;
}
.record-audio-modal .ant-modal-content p {
  color: #444;
}
.record-audio-modal .ant-modal-content .ant-btn {
  width: 200px;
  height: 40px;
  margin: 0 auto;
  display: block;
}
.record-audio-modal .ant-modal-content .ant-btn span {
  font-size: 16px;
  font-weight: 600;
}
