.confirm-appointment-modal .actions-container {
  margin-top: 20px;
}

.confirm-appointment-modal .ant-modal-body p {
  font-size: 16px;
  text-align: center;
}

.confirm-appointment-modal .ant-modal-body .input-container {
  display: flex;
  flex-flow: column;
}

.confirm-appointment-modal .ant-modal-body .input-container label {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
}
.confirm-appointment-modal .ant-modal-body .input-container textarea {
  padding: 10px 20px;
  border-radius: 10px !important;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  color: #323232;
  font-size: 14px;
  letter-spacing: 0.11px;
  text-align: left;
  color: #323232;
}
