.diagnostic-form-drawer  .container-diagnostic-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.diagnostic-form-drawer  .container-diagnostic-card .card-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 15px;
  overflow: hidden;
  transition: all 0.5s ease;
  height: 0;
}
.diagnostic-form-drawer  .container-diagnostic-card .card-content.collapsed {
  animation: collapse 0.3s ease forwards; 
  overflow: hidden;
} 
.diagnostic-form-drawer  .container-diagnostic-card .card-content.expanded {
  animation: expand 0.3s ease forwards; 
  overflow: hidden;
  padding-top: 15px;
}
@keyframes collapse {
  0% {
    height: 500px; /* adjust according to your requirement */
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}
@keyframes expand {
  0% {
    height: 0;
    opacity: 0;
  }

  99% {
    height: 500px; /* adjust according to your requirement */
    opacity: 1;
  }
  100% {
    height: auto; /* adjust according to your requirement */
  }
}


/* toogler-container */
.diagnostic-form-drawer .container-diagnostic-card .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 0 0 0px 30px;
  margin-top: 0px;
  min-height: unset;
}
.diagnostic-form-drawer .container-diagnostic-card .header .status-display-container {
  position: absolute;
  top: 5px;
  left: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .header .status-display-container .anticon {
  font-size: 24px;
  color: var(--primary-color);
}
.diagnostic-form-drawer .container-diagnostic-card .header .identification p {
  margin: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .header .identification .label {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.diagnostic-form-drawer .container-diagnostic-card .header .identification .value {
  color: #444;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.diagnostic-form-drawer .container-diagnostic-card .header .toogler-container {
  height: 20px;
}
.diagnostic-form-drawer .container-diagnostic-card .header .toogler-container .ant-btn {
  max-width: 20px !important;
  max-height: 20px !important;
}
.diagnostic-form-drawer .container-diagnostic-card .header .toogler-container .ant-btn span {
  max-width: 20px !important;
  max-height: 20px !important;
}
.diagnostic-form-drawer .container-diagnostic-card .header .ant-btn {
  border: 0;
  box-shadow: unset;
  padding: 0;
  width: 20px;
  height: 20px;
}
.diagnostic-form-drawer .container-diagnostic-card .header .ant-btn span {
  color: var(--primary-color);
  transition: all 0.5s ease;
}
.diagnostic-form-drawer .container-diagnostic-card .header .ant-btn.expanded span {
  transform: rotate(-180deg);
}

/* hr */
.diagnostic-form-drawer .container-diagnostic-card hr {
  border-top: 1px solid #ccc;
  width: 100%;
  margin: 5px 0 0 0;
}
/* helper */
.diagnostic-form-drawer .container-diagnostic-card .helper {
  color: #000;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
  /* margin-top: 15px; */
  font-weight: 400;
}

/* Main attachment */
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container {
  margin-top: -5px;
}
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container p {
  color: #000;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container p b {
  font-weight: 600 !important;
}
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container .ant-upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: var(--primary-color);
  gap: 5px;
} 
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container .ant-upload-select-text {
  margin-bottom: 5px;
}
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container .ant-upload .anticon {
  width: 15px;
}
.diagnostic-form-drawer .container-diagnostic-card .diagnostic-attachment-container .ant-upload p {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  text-decoration-line: underline;
}


/* Choose container */
.diagnostic-form-drawer .container-diagnostic-card .choose-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container p {
  margin-bottom: 15px;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  width: 100%;
  max-width: 320px;
  place-self: center;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn {
  border-width: 2px;
  width: 100%;
  transition: all 0.5s ease;
  height: auto !important;
  min-height: 40px !important;
  background: #fff;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn span {
  color: #282828;
  font-weight: 600;
  display: block;
  width: 100%;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 14px !important;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):active,
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):focus,
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):hover {
  background: var(--mariner-color) !important;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):active span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):focus span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container .options .ant-btn:not([disabled]):hover span {
  color: #fff !important;
}

/* Choose type */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-type .options {
  flex-direction: row;
}


/* Second phase of choose container */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.second-phase {
  gap: 10px;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.second-phase .title {
  text-align: left;
  margin-bottom: 0px;
  font-weight: 600;
  color: #444;
  font-size: 14px;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.second-phase .label {
  text-align: left;
  margin-bottom: 5px;
}

/* Choose Risk */
@media (min-width: 376px) {
  .diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options {
    width: 100%;
    max-width: unset;
    flex-direction: row;
  }
  .diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn {
    width: calc(33% - 10px);
  }
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn span {
  width: auto;
  margin: 0;
}
/* Danger button */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high {
  border-color: var(--danger-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high span.anticon {
  color: var(--danger-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):active,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):focus,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):hover,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high.selected {
  background: var(--danger-color) !important;
  /* border: none; */
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):active span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):focus span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high:not([disabled]):hover span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--high.selected span {
  color: #fff !important;
}
/* Warning button */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium {
  border-color: var(--warning-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium span.anticon {
  color: var(--warning-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):active,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):focus,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):hover,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium.selected {
  background: var(--warning-color) !important;
  /* border: none; */
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):active span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):focus span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium:not([disabled]):hover span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--medium.selected span {
  color: #444 !important;
}
/* Low risk button */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low {
  border-color: var(--low-risk-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low span.anticon {
  color: var(--low-risk-color);
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):active,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):focus,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):hover,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low.selected {
  background: var(--low-risk-color) !important;
  /* border: none; */
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):active span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):focus span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low:not([disabled]):hover span,
.diagnostic-form-drawer .container-diagnostic-card .choose-container.choose-risk .options .ant-btn.--low.selected span {
  color: #444 !important;
}

/* Type Diagnostic */
.diagnostic-form-drawer .container-diagnostic-card .choose-container.type-diagnostic .input-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.type-diagnostic .attachment-buttons-container {
  width: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.diagnostic-form-drawer .container-diagnostic-card .choose-container.type-diagnostic .attachment-buttons-container .anticon {
  font-size: 22px;
  color: var(--primary-color);
  cursor: pointer;
}

/* Forms */
.diagnostic-form-drawer .container-diagnostic-card .ant-input {
  border: 1px solid #ccc;
  background: #fff;
}

/* Selected Choice Card */
.diagnostic-form-drawer .container-diagnostic-card .selected-choice {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.diagnostic-form-drawer .container-diagnostic-card .selected-choice p {
  margin-bottom: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .selected-choice p.label {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
}
.diagnostic-form-drawer .container-diagnostic-card .selected-choice p.value {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}
.diagnostic-form-drawer .container-diagnostic-card .selected-choice .ant-btn {
  border: 0;
  background-color: transparent;
  box-shadow: none;
}
.diagnostic-form-drawer .container-diagnostic-card .selected-choice .ant-btn span {
  color: var(--primary-color);
}

/* Form item */
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container:last-of-type {
  margin-bottom: 0px;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) {
  max-width: 200px;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) input {
  height: 40px;
  text-align: center;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) .ant-input-number {
  transition: unset;
}

/* Form display */
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display.price-per-unit {
  width: 100%;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display .subtotal-discount {
  color: #444;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display.price-per-unit .label {
  margin-bottom: 5px;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display .price {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display.subtotal {
  margin: 0 auto;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display.subtotal p {
  text-align: center;
  display: block;
  width: 100%;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-display.subtotal .label {
  margin: 0 auto 5px auto;
}


.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) .ant-input-number:focus,
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) .ant-input-number:hover,
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item:not(.description) .ant-input-number-focused {
  box-shadow: unset;
  border-color: #d9d9d9;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item input {
  color: #444;
  font-size: 16px;
  font-weight: 600;
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item.price-per-unit .ant-input-number {
  border-left: 0
}
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .ant-input-number-group > .ant-input-number:first-child,
.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .ant-input-number-group-addon:first-child {
  background-color: #fff;
  color: #808080;
  font-weight: 600;
}

.diagnostic-form-drawer .container-diagnostic-card .quotation-form-container .form-item .label {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 5px;
}

/* Button container */
.container-diagnostic-card .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
}
.container-diagnostic-card .button-container .ant-btn.ant-btn-default {
  height: 30px;
  width: 160px;
  border: none !important;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0 auto 5px auto;
  box-shadow: none;
}
.container-diagnostic-card .button-container .ant-btn span {
  font-size: 14px;
  font-weight: 600;
  color: var(--error-color);
}
.container-diagnostic-card .button-container .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.container-diagnostic-card .button-container .ant-checkbox-wrapper span:not(.ant-checkbox) {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
